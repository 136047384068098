import { ReactNode, createContext, useEffect, useRef, useState } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
gsap.registerPlugin(ScrollTrigger);

export type SlotData = {
  ringRef: React.RefObject<HTMLUListElement>;
  items: (
    | {
        src: string;
      }
    | {
        src: string;
      }
  )[];
};

type AppContextProps = {
  offer: string;

  modalIsOpen: boolean;
  setModalIsOpen: (_: boolean) => void;

  loading: boolean;
  setLoading: (_: boolean) => void;

  round: number;

  ringRefs: React.RefObject<HTMLUListElement>[];

  playCount: number;

  handlePlayClick: () => void;

  victory: boolean;
};

export const AppContext = createContext({} as AppContextProps);

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [offer, setOffer] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [round, setRound] = useState(0);

  useEffect(() => {
    if (window && window.offer) {
      setOffer(window.offer);
    }
  }, [window]);

  // Refs
  const ringRefs = [
    useRef<HTMLUListElement>(null),
    useRef<HTMLUListElement>(null),
    useRef<HTMLUListElement>(null),
  ];

  // States
  const [notStarted, setNotStarted] = useState(true);
  const [playCount, setPlayCount] = useState(0);
  const [victory, setVictory] = useState(false);

  const itemsRef = useRef<NodeListOf<HTMLLIElement> | null>(null);

  useEffect(() => {
    initializeSlotMachine();
  }, [notStarted]);

  const initializeSlotMachine = () => {
    const randomRotations = Array(3)
      .fill(null)
      .map(() => gsap.utils.random(-360, 360, 36));

    const finishScroll = () => {
      itemsRef.current?.forEach((item) => {
        ScrollTrigger.create({
          trigger: item,
          scroller: ".console-outer",
          start: "top center-=59px",
          end: "bottom center-=59px",
          toggleClass: "active",
        });
      });
    };

    gsap
      .timeline({ onComplete: finishScroll })
      .set(".ring", { rotationX: -90 })
      .set(".item", {
        rotateX: (i) => i * -36,
        transformOrigin: "50% 50% -220px",
        z: 220,
      })
      .to(
        "#ring1",
        { rotationX: randomRotations[0], duration: 1, ease: "power3" },
        "<="
      )
      .to(
        "#ring2",
        { rotationX: randomRotations[1], duration: 1, ease: "power3" },
        "<="
      )
      .to(
        "#ring3",
        { rotationX: randomRotations[2], duration: 1, ease: "power3" },
        "<="
      );

    itemsRef.current = document.querySelectorAll(
      ".item"
    ) as NodeListOf<HTMLLIElement>;
  };

  const handlePlayClick = () => {
    if (playCount >= 3) return;

    let randomRotations = Array(3)
      .fill(null)
      .map(() => gsap.utils.random(-1440, 1440, 36));

    if (playCount === 2) {
      randomRotations = [0, 0, 0]; // Garante uma vitória na terceira jogada

      setTimeout(() => setVictory(true), 3000);
    }

    setNotStarted(false);
    setPlayCount(playCount + 1);

    $(".stage").removeClass("notstarted");
    $(".ring:not(.held) .item").removeClass("active");

    const finishScroll = () => {
      itemsRef.current?.forEach((item) => {
        ScrollTrigger.create({
          trigger: item,
          scroller: ".console-outer",
          start: "top center-=59px",
          end: "bottom center-=59px",
          toggleClass: "active",
        });
      });

      const activeItems = [
        "#col1 .item.active",
        "#col2 .item.active",
        "#col3 .item.active",
      ].map((selector) => $(selector).data("content"));

      if (!notStarted) {
        const [item1, item2, item3] = activeItems;
        if (item1 === item2 && item2 === item3) {
        } else if (new Set(activeItems).size === 3) {
        } else {
        }
      }
    };

    gsap
      .timeline({ onComplete: finishScroll })
      .to(
        "#ring1:not(.held)",
        { rotationX: randomRotations[0], duration: 2, ease: "power3" },
        "<"
      )
      .to(
        "#ring2:not(.held)",
        { rotationX: randomRotations[1], duration: 2, ease: "power3" },
        "<"
      )
      .to(
        "#ring3:not(.held)",
        { rotationX: randomRotations[2], duration: 2, ease: "power3" },
        "<"
      )
      .play();
  };

  return (
    <AppContext.Provider
      value={{
        offer,
        modalIsOpen,
        setModalIsOpen,
        loading,
        setLoading,
        round,
        handlePlayClick,
        playCount,
        ringRefs,
        victory,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
