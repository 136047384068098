import "./styles/styles.css";

import styled from "styled-components";
import { useApp } from "./hooks/useApp";
import { OfferContent } from "./components/OfferContent";
import { Slot } from "./components/Slot/Slot";

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const Iframe = styled.iframe`
  width: 100vw;
  height: 100vh;
  border: none;
  opacity: 0.7;
  filter: blur(4px);
  position: absolute;
  left: 0;
  top: 0;
`;

export const App = () => {
  const { victory } = useApp();

  return (
    <AppContainer>
      <Iframe src="https://bet7k.com/"></Iframe>
      <div id="offer-modal">{victory ? <OfferContent /> : <Slot />}</div>
    </AppContainer>
  );
};
