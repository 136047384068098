import { useEffect, useRef, useState } from "react";

// Styles
import "./index.css";
import { useApp } from "../../hooks/useApp";

const slotItems = [
  { id: "bonus", src: "/images/slot/bonus-icon.svg" },
  { id: "a", src: "/images/slot/j-icon.svg" },
  { id: "k", src: "/images/slot/k-icon.svg" },
  { id: "q", src: "/images/slot/q-icon.svg" },
  { id: "j", src: "/images/slot/a-icon.svg" },
];

export const Slot = () => {
  const { ringRefs, handlePlayClick } = useApp();

  return (
    <>
      <img src="/images/slot/headline.svg" alt="" className="headline" />
      <div className="stage notstarted">
        <img src="/images/slot/bg-slot.png" alt="" className="slot-layout" />

        <div className="row console">
          <div className="console-outer">
            {ringRefs.map((ref, index) => (
              <div
                key={index}
                id={`col${index + 1}`}
                className="col third wheel"
              >
                <div className="slot-container">
                  <ul id={`ring${index + 1}`} className="ring" ref={ref}>
                    {slotItems.concat(slotItems).map((item, i) => (
                      <li key={i} className="item">
                        <span role="img">
                          <img src={item.src} />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <button id="play" className="trigger" onClick={handlePlayClick}>
        Girar Agora
      </button>
    </>
  );
};
